import React from "react"

import { withSections } from "./withSections"
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs"
// import { Helmet } from "react-helmet"
import { Script } from "gatsby"

const breadcrumbDetails = {
  title: "Loyalty",
}

export const Sections = withSections(
  ({ page, sections, withBreadcrumbs, ...props }): JSX.Element => (
    <>
      <Script
        type="text/javascript"
        id="yotpo-widgets-loader"
        src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${process.env.GATSBY_YOTPO_GUID}`}
        onLoad={() => {
          // const initYotpo = setInterval(() => {
          //   if (window?.yotpoWidgetsContainer?.initWidgets) {
          //     window?.yotpoWidgetsContainer?.initWidgets()
          //     clearInterval(initYotpo)
          //   }
          // }, 100)
        }}
      />

      <Breadcrumbs page={breadcrumbDetails} />
      {sections?.map((section: any) => (
        <section.component key={section?.id} {...props} {...section} />
      ))}
      {withBreadcrumbs && <Breadcrumbs page={page} />}
    </>
  )
)
