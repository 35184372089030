import React, { memo } from "react"
import * as Components from "../../../types/components"

export const withLoyaltyRewards = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = `LoyaltyRewards`, rewardsTitle, rewardsDescription, sectionBackgroundColour, rewardsOptions }: ExternalProps) => {
    const props = {
      rewardsTitle,
      rewardsDescription,
      sectionBackgroundColour,
      rewardsOptions,
    }
    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  data: any
  rewardsTitle: any
  rewardsDescription: any
  sectionBackgroundColour: any
  rewardsOptions: any
}

type InjectedProps = {
  data: any
  rewardsTitle: any
  rewardsDescription: any
  sectionBackgroundColour: any
  rewardsOptions: any
}
