import tw, { styled } from "twin.macro"
import { H2, H5, P, RichText } from "../../Styled/Text"
import { StyledForm } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"

const styles = {
  default: tw`block`,
  item: tw`border-t border-grey-light`,
  referralContainer: {
    default: tw`flex flex-col justify-center py-14 bg-orange-light-pastel`,
  },
  title: {
    default: tw`text-center text-orange-DEFAULT pb-5 font-bold text-4xl sm:text-5xl md:text-6xl`,
  },
  referralDescriptionContainer: {
    default: tw`flex justify-center pb-5 `,
  },
  referralDescription: {
    default: tw`text-center text-base sm:text-xl text-grey-darker font-light pb-10 w-4/5 md:w-7/12 lg:w-5/12 lg:max-w-xl`,
  },
  referralInput: {
    default: tw`flex justify-center pb-7 w-8/12 sm:w-6/12 md:w-4/12 xl:w-96`,
  },
  referralButtonContainer: {
    default: tw`flex justify-center pb-5 `,
  },
  referralButton: {
    default: tw`bg-orange-DEFAULT text-white py-2 px-4 mx-2 rounded-6xl w-40 text-center`,
  },
  joinButton: {
    default: tw`bg-orange-DEFAULT text-white py-2 px-4 mx-2 rounded-6xl w-40 text-center`,
  },
  referralShareContainer: {
    default: tw`flex items-center flex-row justify-center`,
  },
  referralShare: {
    default: tw`text-black py-2 px-2 sm:px-4 flex`,
  },
  referralButtons: {
    default: tw`text-orange-DEFAULT font-normal text-2xl pb-10 px-4 `,
  },
  referralListContainer: {
    default: tw`text-orange-DEFAULT font-normal text-2xl pb-10 px-4`,
  },
  referralListItem: {
    default: tw`text-orange-DEFAULT font-normal text-2xl pb-10 px-4 `,
  },
}

export const ReferralContainer = styled(RichText)`
  ${styles.referralContainer.default}
`

export const Title = styled(H2)`
  ${styles.title.default}
`

export const ReferralDescriptionContainer = styled(RichText)`
  ${styles.referralDescriptionContainer.default}
`

export const ReferralDescription = styled(H5)`
  ${styles.referralDescription.default}
`
export const ReferralInput = styled(RichText)`
  ${styles.referralInput.default}

  > div {
    width: 100%;
    margin-bottom: 0;
  }
`
export const ReferralButtonContainer = styled(P)`
  ${styles.referralButtonContainer.default}
`
export const ReferralButton = styled(StyledButton)`
  ${styles.referralButton.default}
`
export const JoinButton = styled(RichText)`
  ${styles.joinButton.default}

  color: white !important;
  :visited {
    color: white !important;
  }
`
export const ReferralShareContainer = styled(P)`
  ${styles.referralShareContainer.default}
`

export const ReferralShare = styled(P)`
  ${styles.referralShare.default}
`
export const ReferralButtons = styled(P)`
  ${styles.referralButtons.default}
`
export const ReferralListContainer = styled(RichText)`
  ${styles.referralListContainer.default}
`
export const ReferralListItem = styled(RichText)`
  ${styles.referralListItem.default}
`

export const Link = tw.div`block focus:outline-none`
export const LinkText = tw.div`pl-1 text-sm`
export const Form = tw(StyledForm)`flex flex-col items-center w-full`
