import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withLoyaltyIntroduction } from "./withLoyaltyIntroduction"
import {
  LoyaltyIntroductionContainer,
  Title,
  IntroductionInformation,
  InformationStep,
  StepTitle,
  StepDescription,
  PointDetail,
  AccountButtonContainer,
  VipTier,
  AccountStats,
  LearnMoreButton,
  Button,
  Link,
  VipLine,
  VipBasketContainer,
  VipBasket,
} from "./styledLoyaltyIntroduction"

export const LoyaltyIntroduction = withLoyaltyIntroduction(
  ({
    introGreeting,
    link,
    vipTierText,
    annualSpendIcon,
    annualSpendText,
    nextTierText,
    learnMore,
    yotpo,
    data,
    currentRoute,
    customer,
    sectionTitle,
    basketPos,
    sectionInformation,
    handleClick,
  }) => (
    <>
      {customer?.id && yotpo?.userData ? (
        // Shows UI when user is logged in
        <LoyaltyIntroductionContainer page={currentRoute}>
          <Title page={currentRoute}>
            {introGreeting}

            {currentRoute != "/account/rewards" && yotpo?.userData?.first_name ? " " + yotpo?.userData?.first_name + `!` : ""}
          </Title>
          <PointDetail page={currentRoute}>
            You have <span className="font-bold">{yotpo?.userData?.points_balance ? yotpo?.userData?.points_balance : " "} </span>points.
          </PointDetail>
          <AccountButtonContainer>
            {/* eslint-disable-next-line react/no-unknown-property */}
            {/* <div className="yotpo-widget-instance" data-yotpo-instance-id="244925" mode-rewards-history-only="true"></div> */}
            {link?.map((item, i) =>
              item?.title?.toLowerCase() === "rewards history" ? (
                <Button key={i} className="yotpo-rewards-pop-up w-auto" page={currentRoute} onClick={handleClick}>
                  {item?.title}
                </Button>
              ) : (
                <Link key={i} as={GatsbyLink} to={item?.resolvedLink?.url}>
                  <Button page={currentRoute} key={i}>
                    {item?.title}
                  </Button>
                </Link>
              )
            )}
          </AccountButtonContainer>
          {yotpo?.userData?.vip_tier_name?.toLowerCase() !== "base" && (
            <VipTier>
              {vipTierText} {yotpo?.userData?.vip_tier_name}
            </VipTier>
          )}

          <VipBasketContainer>
            <VipLine>
              <VipBasket iconPosition={basketPos} image={{ ...annualSpendIcon }}></VipBasket>
            </VipLine>
          </VipBasketContainer>

          <AccountStats>
            {annualSpendText}{" "}
            {yotpo?.userData.vip_tier_actions_completed?.amount_spent_cents_in_customer_currency !== undefined
              ? `$${yotpo?.userData.vip_tier_actions_completed?.amount_spent_cents_in_customer_currency / 100}`
              : ""}{" "}
            <br></br>
            {yotpo?.userData.vip_tier_upgrade_requirements?.amount_cents_needed_in_customer_currency >= 0
              ? `$${yotpo?.userData.vip_tier_upgrade_requirements?.amount_cents_needed_in_customer_currency / 100}`
              : ""}{" "}
            {nextTierText}
          </AccountStats>

          {currentRoute == "/account/rewards" ? (
            " "
          ) : (
            <Link as={GatsbyLink} to={learnMore?.url}>
              <LearnMoreButton>Learn more</LearnMoreButton>
            </Link>
          )}
        </LoyaltyIntroductionContainer>
      ) : (
        // Shows UI when user is logged out
        <LoyaltyIntroductionContainer>
          <Title loggedOut>{sectionTitle}</Title>
          <IntroductionInformation>
            {sectionInformation?.map((item, i) => (
              <InformationStep key={i}>
                <StepTitle>{item?.sectionTitle}</StepTitle> <StepDescription>{item?.sectionText}</StepDescription>{" "}
              </InformationStep>
            ))}
          </IntroductionInformation>
        </LoyaltyIntroductionContainer>
      )}
    </>
  )
)

export default LoyaltyIntroduction
