import React, { memo, useState } from "react"

import * as Components from "../../../types/components"
import { useCore } from "../../../hooks/useCore"
import { useImage } from "../../../hooks/useImage"
import { useFunctions } from "../../../hooks/useFunctions"

export const withLoyaltyEarn = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = `LoyaltyEarn`, data, headerImage, earnTitle, earnDescription, earnInformation, yotpo, customer }: ExternalProps) => {
    const { getFluidImage } = useImage()
    const {
      helpers: { sanityContent },
    } = useCore()

    const { callFunction, errors, loading } = useFunctions()
    // const { yotpoCustomerActions: submit } = useFunctions()

    const [userComplete, setUserComplete] = useState({
      account: false,
      instagram: false,
      facebook: false,
      tiktok: false,
    })

    const userData = yotpo.userData

    // Checks to see if the current logged in user has completed the below actions
    if (customer?.id && userData) {
      userComplete.account = true

      userData["history_items"]?.map(item => {
        if (item["action"] === "Follow us on Instagram" && userComplete.instagram === false) {
          setUserComplete({ ...userComplete, instagram: true })
        } else if (item["action"] === "Follow us on Facebook" && userComplete.facebook === false) {
          setUserComplete({ ...userComplete, facebook: true })
        } else if (item["action"] === "Follow us on TikTok" && userComplete.tiktok === false) {
          setUserComplete({ ...userComplete, tiktok: true })
        } else {
          // noOp
        }
      })
    }

    // Maps earn info to fix icon src
    earnInformation?.map(item => {
      const earnIconSRC = getFluidImage(item?.earnIcon?.desktop, { maxWidth: 2000 })
      item.earnIconUpdated = earnIconSRC
    })

    // Handles when a user clicks one of the earn icons
    // Set up this way with 3 submits to stop a user sending an API request if they're all complete
    const handleCustomerAction = async actionIdentifier => {
      let response = {}
      if (actionIdentifier === "instagram" && userComplete.instagram === false) {
        window.open("https://www.instagram.com/skinjuice/", "_blank")
        setUserComplete({ ...userComplete, instagram: true })
        // await submit({ myEmail: customer?.email, actionIdentifier })
        response = await callFunction("yotpo-record-customer-action", { myEmail: customer?.email, actionIdentifier })
      } else if (actionIdentifier === "tiktok" && userComplete.tiktok === false) {
        window.open("https://www.tiktok.com/@skinjuiceau", "_blank")
        setUserComplete({ ...userComplete, tiktok: true })
        // await submit({ myEmail: customer?.email, actionIdentifier })
        response = await callFunction("yotpo-record-customer-action", { myEmail: customer?.email, actionIdentifier })
      } else if (actionIdentifier === "facebook" && userComplete.facebook === false) {
        window.open("https://www.facebook.com/skinjuicenaturalskincare/", "_blank")
        setUserComplete({ ...userComplete, facebook: true })
        // await submit({ myEmail: customer?.email, actionIdentifier })
        response = await callFunction("yotpo-record-customer-action", { myEmail: customer?.email, actionIdentifier })
      } else {
        // noOp
      }
      console.log(response)
    }

    const props = {
      data,
      headerImage: getFluidImage(headerImage?.desktop, { maxWidth: 2000 }),
      earnTitle,
      earnDescription: sanityContent(earnDescription),
      earnInformation,
      yotpo,
      customer,
      userComplete,
      handleCustomerAction,
    }
    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  data: any
  headerImage: any
  earnTitle: any
  earnDescription: any
  earnInformation: any
  yotpo: any
  customer: any
  userComplete: any
  handleCustomerAction: () => void
}

type InjectedProps = {
  data: any
  headerImage: any
  earnTitle: any
  earnDescription: any
  earnInformation: any
  yotpo: any
  customer: any
  userComplete: any
  handleCustomerAction: () => void
}
