import React from "react"

import { withAccordion } from "./withAccordion"
import { Accordion as Accordions } from "../../Accordion/Accordion"
import { StyledContainer } from "../../Styled/Container"
import { Section, Title } from "./styledAccordion"

export const Accordion = withAccordion(
  ({ items, tag, theme, title, titleShort }): JSX.Element => (
    <Section colour={theme?.background}>
      <StyledContainer width={`lg`}>
        {title && (
          <Title as={tag} colour={theme?.colour} screen={titleShort ? `md>` : null}>
            {title}
          </Title>
        )}
        {titleShort && (
          <Title as={tag} colour={theme?.colour} screen={`<sm`}>
            {titleShort}
          </Title>
        )}
        <Accordions items={items} colour={theme?.colour} />
      </StyledContainer>
    </Section>
  )
)

export default Accordion
