import React from "react"

import { withLoyaltyEarn } from "./withLoyaltyEarn"
import {
  EarnContainer,
  Title,
  EarnDescriptionContainer,
  EarnDescription,
  EarnChallengesContainer,
  EarnChallenge,
  EarnChallengeIcon,
  EarnChallengeReward,
  EarnChallengeDescription,
  StyledImage,
  EarnChallengeRewardDone,
} from "./styledLoyaltyEarn"

export const LoyaltyEarn = withLoyaltyEarn(
  ({ headerImage, earnTitle, earnDescription, earnInformation, yotpo, customer, userComplete, handleCustomerAction }) => (
    <EarnContainer>
      <StyledImage image={{ ...headerImage }} />
      <EarnDescriptionContainer>
        <Title>{earnTitle}</Title>
        <EarnDescription>{earnDescription}</EarnDescription>
      </EarnDescriptionContainer>

      <EarnChallengesContainer>
        <div
          id="swell-customer-identification"
          data-authenticated={!!customer}
          data-email={customer?.email}
          data-id={customer?.id?.split("/")?.pop()}
          data-tags={JSON.stringify(customer?.tags)}
          style={{ display: "none" }}
        ></div>
        {/* {earnInformation?.map((item, i) => (
          <EarnChallenge onClick={() => handleCustomerAction(item?.earnIdentifier)} key={i}>
            <EarnChallengeIcon image={item?.earnIconUpdated}> {item?.earnIconUpdated} </EarnChallengeIcon>
            <EarnChallengeReward>
              {userComplete?.account === true ? (
                item?.earnIdentifier === "account" ? (
                  <EarnChallengeRewardDone>Done!</EarnChallengeRewardDone>
                ) : item?.earnIdentifier === "facebook" ? (
                  userComplete?.facebook === true ? (
                    <EarnChallengeRewardDone>Done!</EarnChallengeRewardDone>
                  ) : (
                    item?.earnPoints
                  )
                ) : item?.earnIdentifier === "instagram" ? (
                  userComplete?.instagram === true ? (
                    <EarnChallengeRewardDone>Done!</EarnChallengeRewardDone>
                  ) : (
                    item?.earnPoints
                  )
                ) : item?.earnIdentifier === "tiktok" ? (
                  userComplete?.tiktok === true ? (
                    <EarnChallengeRewardDone>Done!</EarnChallengeRewardDone>
                  ) : (
                    item?.earnPoints
                  )
                ) : (
                  item?.earnPoints
                )
              ) : (
                item?.earnPoints
              )}
            </EarnChallengeReward>
            <EarnChallengeDescription>
              {userComplete?.account === true
                ? item?.earnIdentifier === "account"
                  ? item?.earnTextComplete
                  : item?.earnIdentifier === "facebook"
                  ? userComplete?.facebook === true
                    ? item?.earnTextComplete
                    : item?.earnTextNotComplete
                  : item?.earnIdentifier === "instagram"
                  ? userComplete?.instagram === true
                    ? item?.earnTextComplete
                    : item?.earnTextNotComplete
                  : item?.earnIdentifier === "tiktok"
                  ? userComplete?.tiktok === true
                    ? item?.earnTextComplete
                    : item?.earnTextNotComplete
                  : item?.earnTextNotComplete
                : item?.earnTextNotComplete}
            </EarnChallengeDescription>
          </EarnChallenge>
        ))} */}
      </EarnChallengesContainer>
      <div className="yotpo-widget-instance" data-yotpo-instance-id={process.env.GATSBY_ACTIVE_ENV === "staging" ? 194556 : 160433}></div>
    </EarnContainer>
  )
)

export default LoyaltyEarn
