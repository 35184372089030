import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withLoyaltyHero } from "./withLoyaltyHero"
import {
  HeroContainer,
  Title,
  HeroDescriptionContainer,
  HeroDescription,
  HeroButtonContainer,
  HeroButtons,
  StyledImage,
  StyledImageWrapper,
  StyledBackgroundImage,
  Link,
} from "./styledLoyaltyHero"

export const LoyaltyHero = withLoyaltyHero(({ heroTitle, heroBackgroundImage, heroLogo, heroInfo, link, customer }) => (
  <StyledImageWrapper backgroundDesktop={heroBackgroundImage?.desktop.src} backgroundMobile={heroBackgroundImage?.mobile.src}>
    {" "}
    <StyledBackgroundImage />
    <HeroContainer>
      <Title>{heroTitle}</Title>
      <StyledImage image={{ ...heroLogo }} />
      <HeroDescriptionContainer>
        <HeroDescription>{heroInfo}</HeroDescription>
      </HeroDescriptionContainer>

      {!customer?.id ? (
        <HeroButtonContainer>
          {link.map((item, i) => (
            <HeroButtons key={i}>
              <Link as={item?.resolvedLink ? GatsbyLink : null} to={item.resolvedLink.url}>
                {item.title}
              </Link>
            </HeroButtons>
          ))}
        </HeroButtonContainer>
      ) : (
        ""
      )}
    </HeroContainer>
  </StyledImageWrapper>
))

export default LoyaltyHero
