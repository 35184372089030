import React from "react"
import { Icon } from "../../Icon/Icon"
import { Link as GatsbyLink } from "gatsby"

import { withLoyaltyRewardTiers } from "./withLoyaltyRewardTiers"
import {
  TierSectionContainer,
  Title,
  TierInfoContainer,
  TierDescription,
  TiersContainer,
  TierContainer,
  TierImage,
  TierLevel,
  TierName,
  TierSpend,
  TierRewardsContainer,
  TierRewards,
  OrchardButton,
  TermsLink,
  Link,
} from "./styledLoyaltyRewardTiers"

export const LoyaltyRewardTiers = withLoyaltyRewardTiers(
  ({ headerImage, rewardsTitle, rewardsDescription, rewardsTiers, yotpo, customer, currentRoute, orchardButton, termsLink }) => (
    <TierSectionContainer page={currentRoute}>
      {currentRoute == "/account/rewards" ? (
        ""
      ) : (
        <TierInfoContainer page={currentRoute}>
          {/* Checks to see if user logged in  */}
          {!customer?.id ? (
            <Title> {rewardsTitle} </Title>
          ) : (
            yotpo?.userData?.vip_tier_name?.toLowerCase() !== "base" && <Title>You&apos;re a {yotpo?.userData?.vip_tier_name} member!</Title>
          )}

          {/* Checks to see if user logged in */}
          {!customer?.id ? <TierDescription>{rewardsDescription}</TierDescription> : ``}
        </TierInfoContainer>
      )}

      <TiersContainer>
        {rewardsTiers?.map((item, i) => (
          <TierContainer
            key={i}
            page={currentRoute}
            active={item?.tierName == yotpo?.userData?.vip_tier_name ? yotpo?.userData?.vip_tier_name : ""}
            colour={item.tiercolour}
          >
            <TierImage image={item.rewardTierIconUpdated} />
            <TierLevel colour={item.tiercolour}>{item.tierNumber}</TierLevel>
            <TierName colour={item.tiercolour}>{item.tierName}</TierName>
            <TierSpend page={currentRoute}>{item.tierAnnualSpend}</TierSpend>
            <TierRewardsContainer>
              {item["tierBenefits"]?.map((benefit, j) => (
                <TierRewards key={`${i}-${j}`} dropColour={item.dropColour}>
                  <Icon name={`drop`} width={"6"} />
                  {benefit}
                </TierRewards>
              ))}
            </TierRewardsContainer>
          </TierContainer>
        ))}
      </TiersContainer>

      {orchardButton ? (
        <OrchardButton>
          <Link as={GatsbyLink} to={orchardButton?.resolved?.url}>
            {orchardButton?.title}
          </Link>
        </OrchardButton>
      ) : (
        ""
      )}

      {termsLink ? (
        <TermsLink>
          <Link as={GatsbyLink} to={termsLink?.resolved?.url}>
            {termsLink?.title}
          </Link>
        </TermsLink>
      ) : (
        ""
      )}
    </TierSectionContainer>
  )
)

export default LoyaltyRewardTiers
