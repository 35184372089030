import React from "react"

import { withLoyaltyRewards } from "./withLoyaltyRewards"
import {
  LoyaltyRewardsContainer,
  Title,
  Description,
  RewardOptions,
  RewardOption,
  RewardOptionPoints,
  RewardOptionAmount,
} from "./styledLoyaltyRewards"

export const LoyaltyRewards = withLoyaltyRewards(({ rewardsTitle, rewardsDescription, rewardsOptions }) => (
  <LoyaltyRewardsContainer>
    <Title>{rewardsTitle}</Title>
    <Description>{rewardsDescription}</Description>

    <RewardOptions>
      {rewardsOptions.map((item, i) => (
        <RewardOption key={i}>
          <RewardOptionPoints>{item.rewardPoints}</RewardOptionPoints>
          <RewardOptionAmount>{item.rewardAmount}</RewardOptionAmount>
        </RewardOption>
      ))}
    </RewardOptions>
  </LoyaltyRewardsContainer>
))

export default LoyaltyRewards
