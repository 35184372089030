import React, { memo } from "react"
import * as Components from "../../../types/components"
import { useImage } from "../../../hooks/useImage"
import { useCore } from "../../../hooks/useCore"
import { useRoutes } from "../../../hooks/useRoutes"

export const withLoyaltyHero = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = `LoyaltyHero`, heroTitle, heroBackgroundImage, heroInfo, heroLogo, link, customer }: ExternalProps) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { getFluidImage, getResponsiveImage } = useImage()
    const { linkResolver } = useRoutes()

    link.map(item => {
      const linkResolved = linkResolver(item?.document)
      item.resolvedLink = linkResolved
    })

    const props = {
      heroTitle,
      heroBackgroundImage: getResponsiveImage(heroBackgroundImage, { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 800 } }),
      heroLogo: getFluidImage(heroLogo?.desktop, { maxWidth: 2000 }),
      link,
      heroInfo: sanityContent(heroInfo),
      customer,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  heroTitle: any
  heroBackgroundImage: any
  heroLogo: any
  link: any
  heroInfo: any
  item: any
  customer: any
}

type InjectedProps = {
  heroTitle: any
  heroBackgroundImage: any
  heroLogo: any
  link: any
  item: any
  heroInfo: any
  customer: any
}
