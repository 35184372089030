import tw, { styled } from "twin.macro"
import { H2, P, RichText } from "../../Styled/Text"

const styles = {
  default: tw`block`,
  item: tw`border-t border-grey-light`,
  loyaltyRewardsContainer: {
    default: tw`flex flex-col justify-center bg-orange-DEFAULT pt-34 pb-20`,
  },
  title: {
    default: tw`text-center text-white pb-3 text-4xl sm:text-5xl md:text-6xl`,
  },
  description: {
    default: tw`text-center text-white text-2xl pb-8 text-base`,
  },
  rewardOptions: {
    default: tw`flex flex-wrap justify-center max-w-screen-xl my-0 mx-auto`,
  },
  rewardOption: {
    default: tw`flex flex-col text-white md:px-5 lg:px-10 w-10/12 sm:w-1/4 md:w-max sm:border-r last:border-r-0 border-b last:border-b-0 sm:border-b-0 pb-3 sm:pb-0 m-3 sm:m-0 `,
  },
  rewardOptionPoints: {
    default: tw`after:h-0 pb-1 text-white text-center text-3xl sm:text-base md:text-2xl lg:text-3xl`,
  },
  rewardOptionAmount: {
    default: tw`text-white text-center font-bold text-4xl sm:text-6xl md:text-7xl lg:text-8xl`,
  },
}

export const LoyaltyRewardsContainer = styled(RichText)`
  ${styles.loyaltyRewardsContainer.default}
`

export const Title = styled(H2)`
  ${styles.title.default}
`

export const Description = styled(P)`
  ${styles.description.default}
`

export const RewardOptions = styled(RichText)`
  ${styles.rewardOptions.default}
`
export const RewardOption = styled(RichText)`
  ${styles.rewardOption.default}
`
export const RewardOptionPoints = styled(P)`
  ${styles.rewardOptionPoints.default}
`

export const RewardOptionAmount = styled(P)`
  ${styles.rewardOptionAmount.default}
`
