import React from "react"
import { graphql } from "gatsby"

import { Sections as Page } from "../components/Loyalty/Sections"

export const query = graphql`
  query {
    page: sanityPageLoyalty {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
