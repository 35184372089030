import React from "react"
import { Icon } from "../../Icon/Icon"
import { Link as GatsbyLink } from "gatsby"

import { FormInput } from "../../Form/Input/FormInput"
import { withLoyaltyReferrals } from "./withLoyaltyReferrals"
import {
  ReferralContainer,
  Title,
  ReferralDescriptionContainer,
  ReferralDescription,
  ReferralInput,
  ReferralButtonContainer,
  ReferralButton,
  ReferralShareContainer,
  ReferralShare,
  ReferralButtons,
  ReferralListContainer,
  ReferralListItem,
  JoinButton,
  LinkText,
  Form,
} from "./styledLoyaltyReferrals"

export const LoyaltyReferrals = withLoyaltyReferrals(
  ({ referralHeadings, referralTitle, referralDescription, buttonText, shareOptions, customer, handleSubmit, handleChange }) => (
    <ReferralContainer>
      {customer?.id ? (
        <div className="yotpo-widget-instance" data-yotpo-instance-id={process.env.GATSBY_ACTIVE_ENV === "staging" ? 244925 : 160434}></div>
      ) : (
        <div>
          <Title>{referralTitle}</Title>
          <ReferralDescriptionContainer>
            <ReferralDescription>{referralDescription}</ReferralDescription>
          </ReferralDescriptionContainer>
          <ReferralButtonContainer>
            <JoinButton as={GatsbyLink} to={"https://skinjuice.com.au/account/register/"}>
              Join now
            </JoinButton>
          </ReferralButtonContainer>
        </div>
      )}
    </ReferralContainer>
  )
)

export default LoyaltyReferrals
