import React, { memo, useMemo } from "react"
import VisibilitySensor from "react-visibility-sensor"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useCore } from "../../../hooks/useCore"
import { RichText } from "../../Styled/Text"

export const withAccordion = Component =>
  memo(({ name = "Accordion", items: rawItems, tag, title, titleShort = null, analytics = {}, ...settings }: any) => {
    const { trackPromoImpression } = useAnalytics()
    const {
      helpers: { sanityContent },
    } = useCore()

    const items = rawItems?.map((item: any) => ({
      ...item,
      children: item?.content ? <RichText>{sanityContent(item?.content)}</RichText> : null,
    }))
    const theme = useMemo(
      () => ({
        background: settings?.background?.toLowerCase()?.replace(/ /g, `-`) || `white`,
        colour: settings?.heading?.toLowerCase()?.replace(/ /g, `-`) || `dark`,
      }),
      [settings]
    )

    Component.displayName = name
    return useMemo(
      () =>
        items?.length > 0 ? (
          <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...analytics })}>
            <Component items={items} tag={tag} theme={theme} title={title} titleShort={titleShort} />
          </VisibilitySensor>
        ) : null,
      [analytics, items, tag, theme, title, titleShort, trackPromoImpression]
    )
  })
