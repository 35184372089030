import React, { memo, useMemo, useEffect } from "react"

import { useCore } from "../../hooks/useCore"
import { useLoyalty } from "../../hooks/useLoyalty"
import { useCustomerContext } from "../../hooks/useCustomer"

export const withSections = Component =>
  memo(({ name = "Sections", breadcrumbs: withBreadcrumbs = false, page, path, ...props }: any) => {
    const {
      helpers: { ErrorBoundary, isDomReady, isBrowser },
    } = useCore()
    const { customer } = useCustomerContext()

    // used to determine if the page is /loyalty or /account/rewards for introduction section and rewards tier sections.
    const currentRoute = isBrowser ? window.location.pathname : null

    // Pass users yotpo data
    const loyaltyUserData: any = useLoyalty()

    const sections = page?.content
      ?.slice(0, isDomReady ? page?.content?.length : 1)
      ?.map((item: any, index: number) => {
        const section = {
          ...item,
          name: item?._type?.replace("section", ""),
          id: `${name}-${item?._key}`,
        }

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const SectionComponent = require(`./${section.name}/${section.name}`).default

        return SectionComponent
          ? {
              ...section,
              component: () => (
                <ErrorBoundary>
                  <SectionComponent {...props} {...section} index={index} currentRoute={currentRoute} yotpo={loyaltyUserData} customer={customer} />
                </ErrorBoundary>
              ),
            }
          : null
      })
      ?.filter(Boolean)

    Component.displayName = name

    useEffect(() => {
      if (loyaltyUserData?.userData && window?.yotpoWidgetsContainer?.initWidgets) {
        window?.yotpoWidgetsContainer?.initWidgets()
      }
    })

    return useMemo(
      () =>
        sections?.length > 0 ? (
          <Component {...props} page={page} sections={sections} withBreadcrumbs={withBreadcrumbs} yotpo={loyaltyUserData} customer={customer} />
        ) : null,
      [props, page, sections, withBreadcrumbs, customer, loyaltyUserData]
    )
  })
