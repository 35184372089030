import React, { memo, useCallback } from "react"
import * as Components from "../../../types/components"
import { useFunctions } from "../../../hooks/useFunctions"

export const withLoyaltyReferrals = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = `LoyaltyReferrals`,
      referralHeadings,
      referralTitle,
      referralDescription,
      buttonText,
      sectionBackgroundColour,
      shareOptions,
      customer,
      yotpo,
    }: ExternalProps) => {
      const userReferralData = yotpo?.userData?.referral_code?.completed_referral_customers

      const props = {
        referralHeadings,
        referralTitle,
        referralDescription,
        buttonText,
        sectionBackgroundColour,
        shareOptions,
        customer,
        yotpo,
        userReferralData,
      }
      // console.log("shareOptions", shareOptions)
      // const { yotpoCustomerReferrals: submit } = useFunctions()
      const { callFunction, errors, loading } = useFunctions()

      let emailHandle

      const handleSubmit = useCallback(
        async event => {
          event.preventDefault()
          // submit({ myEmail: customer?.email, referredEmail: emailHandle })
          const response = await callFunction("yotpo-customer-referrals", { myEmail: customer?.email, referredEmail: emailHandle })
        },
        [callFunction, customer?.email, emailHandle]
      )

      const handleChange = useCallback(({ target: { value } }) => {
        emailHandle = value
      }, [])

      Component.displayName = name
      return <Component {...(props as P)} handleSubmit={handleSubmit} handleChange={handleChange} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  data: any
  referralHeadings: any
  referralTitle: any
  referralDescription: any
  buttonText: any
  sectionBackgroundColour: any
  shareOptions: any
  customer: any
  yotpo: any
  userReferralData: any
  handleChange: any
  handleSubmit: any
}

type InjectedProps = {
  data: any
  referralHeadings: any
  referralTitle: any
  referralDescription: any
  buttonText: any
  sectionBackgroundColour: any
  shareOptions: any
  customer: any
  yotpo: any
  userReferralData: any
  handleChange: any
  handleSubmit: any
}
