import React, { memo } from "react"
import * as Components from "../../../types/components"
import { useCore } from "../../../hooks/useCore"
import { useImage } from "../../../hooks/useImage"
import { useRoutes } from "../../../hooks/useRoutes"

export const withLoyaltyRewardTiers = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = `LoyaltyRewardTiers`,
      headerImage,
      rewardsTitle,
      rewardsDescription,
      rewardsTiers,
      yotpo,
      customer,
      currentRoute,
      orchardButton,
      termsLink,
    }: ExternalProps) => {
      const {
        helpers: { sanityContent },
      } = useCore()
      const { getFluidImage, getResponsiveImage } = useImage()
      const { linkResolver } = useRoutes()

      rewardsTiers.map(item => {
        const rewardTierIcon = getFluidImage(item?.tierIcon?.desktop, { maxWidth: 2000 })
        item.rewardTierIconUpdated = rewardTierIcon

        // used to create the drop icon colour for each reward tier reward
        if (item.tiercolour == "Green") {
          item.dropColour = "#84BD00"
        } else if (item.tiercolour == "Orange") {
          item.dropColour = "#FF9F37"
        } else {
          item.dropColour = "#F96E87"
        }
      })

      if (orchardButton) {
        const orchardButtonUrl = linkResolver(orchardButton?.document)
        orchardButton.resolved = orchardButtonUrl
      }

      if (termsLink) {
        const termsLinkUrl = linkResolver(termsLink?.document)
        termsLink.resolved = termsLinkUrl
      }

      const props = {
        headerImage: getFluidImage(headerImage?.desktop, { maxWidth: 2000 }),
        rewardsTitle,
        rewardsDescription: sanityContent(rewardsDescription),
        rewardsTiers,
        yotpo,
        customer,
        currentRoute,
        orchardButton,
        termsLink,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  data: any
  headerImage: any
  rewardsTitle: any
  rewardsDescription: any
  rewardsTiers: any
  yotpo: any
  customer: any
  currentRoute: any
  orchardButton: any
  termsLink: any
}

type InjectedProps = {
  data: any
  headerImage: any
  rewardsTitle: any
  rewardsDescription: any
  rewardsTiers: any
  yotpo: any
  customer: any
  currentRoute: any
  orchardButton: any
  termsLink: any
}
