import React from "react"
import { Icon } from "../../Icon/Icon"
import { Link as GatsbyLink } from "gatsby"

import { withLoyaltyImageHalfColour } from "./withLoyaltyImageHalfColours"
import { TierSectionContainer, StyledImage } from "./styledLoyaltyImageHalfColour"

export const LoyaltyImageHalfColour = withLoyaltyImageHalfColour(({ headerImage }) => (
  <TierSectionContainer>
    <StyledImage image={{ ...headerImage }} />
  </TierSectionContainer>
))

export default LoyaltyImageHalfColour
