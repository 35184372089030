import tw, { css, styled } from "twin.macro"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { H2, H5, P, RichText } from "../../Styled/Text"

const styles = {
  default: tw`block`,
  item: tw`border-t border-grey-light`,
  earnContainer: {
    default: tw`flex flex-col justify-center py-10 bg-orange-light-pastel	relative pt-52`,
  },
  title: {
    default: tw`text-center text-grey-darker sm:pb-5 font-medium font-bold text-4xl sm:text-5xl md:text-6xl `,
  },
  earnImage: {
    default: tw` pb-5 `,
  },
  earnDescriptionContainer: {
    default: tw`flex flex-col justify-center items-center max-w-screen-xl my-0 mx-auto `,
  },
  earnDescription: {
    default: tw`text-center text-lg text-grey-darker sm:pb-5 w-10/12 sm:w-8/12 md:w-6/12 lg:w-7/12 `,
  },
  earnChallengesContainer: {
    default: tw`flex flex-wrap mx-auto my-0 justify-center w-11/12 md:w-8/12 lg:w-6/12`,
  },
  earnChallenge: {
    default: tw`px-1 sm:px-5 py-2 pb-10 sm:pb-0 sm:py-5 w-1/2 sm:w-1/3`,
  },
  earnChallengeIcon: {
    default: tw`after:h-0 w-8/12 xl:w-5/12 text-center flex my-0 mx-auto`,
  },
  earnChallengeReward: {
    default: tw`after:h-0 text-center font-bold text-grey-darker pb-2 sm:pb-0 text-5xl sm:text-6xl`,
  },
  earnChallengeRewardDone: {
    default: tw`after:h-0 text-center font-bold text-orange pb-2 sm:pb-3 text-3xl sm:text-4xl`,
  },
  earnChallengeDescription: {
    default: tw`text-center text-grey-dark w-30 sm:w-40 my-0 mx-auto text-base `,
  },
  styledImages: {
    default: tw`w-80 md:w-96 h-80 md:h-96 my-0 mx-auto absolute inset-x-0 `,
  },
}

export const EarnContainer = styled(RichText)`
  ${styles.earnContainer.default}
`

export const Title = styled(H2)`
  ${styles.title.default}
`

export const EarnDescriptionContainer = styled(RichText)`
  ${styles.earnDescriptionContainer.default}
`

export const EarnDescription = styled(P)`
  ${styles.earnDescription.default}
`
export const EarnChallengesContainer = styled(RichText)`
  ${styles.earnChallengesContainer.default}
`
export const EarnChallenge = styled(RichText)`
  ${styles.earnChallenge.default}
`
export const EarnChallengeIcon = styled(GatsbyImage)`
  ${styles.earnChallengeIcon.default}

  picture > img {
    object-fit: contain !important;
  }
`
export const EarnChallengeReward = styled(P)`
  ${styles.earnChallengeReward.default}
`

export const EarnChallengeRewardDone = styled(P)`
  ${styles.earnChallengeRewardDone.default}
  line-height: 1;
`

export const EarnChallengeDescription = styled(P)`
  ${styles.earnChallengeDescription.default}
`

export const StyledImage = styled(GatsbyImage)`
  ${styles.styledImages.default}

  position: absolute !important;
  top: -10rem;

  @media only screen and (min-width: 768px) {
    top: -10rem;
  }
`
