import React, { memo, useCallback } from "react"
import * as Components from "../../../types/components"
import { useRoutes } from "../../../hooks/useRoutes"
import { useImage } from "../../../hooks/useImage"
import { useLoyalty } from "../../../hooks/useLoyalty"

export const withLoyaltyIntroduction = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = `LoyaltyIntroduction`,
      introGreeting,
      link,
      vipTierText,
      annualSpendIcon,
      annualSpendText,
      nextTierText,
      learnMore,
      sectionTitle,
      sectionInformation,
      yotpo,
      customer,
      data,
      currentRoute,
    }: ExternalProps) => {
      const { linkResolver } = useRoutes()
      const { getFluidImage } = useImage()

      const { handleAnimationPopup } = useLoyalty()

      const handleClick = useCallback(async () => {
        await handleAnimationPopup(yotpo?.userData)
      }, [handleAnimationPopup, yotpo?.userData])

      link.map(item => {
        const linkResolved = linkResolver(item?.document)
        item.resolvedLink = linkResolved
      })

      // Due to element width and height, Start of line is -19px, End of line is 773

      let basketPos
      const spentAmount = yotpo?.userData?.vip_tier_actions_completed?.amount_spent_cents_in_customer_currency / 100 || 0

      if (spentAmount > 900) {
        basketPos = ((900 - 35) / 900) * 100
      } else {
        basketPos = ((spentAmount - 35) / 900) * 100
      }

      const props = {
        introGreeting,
        link,
        vipTierText,
        annualSpendIcon: getFluidImage(annualSpendIcon?.desktop, { maxWidth: 2000 }),
        annualSpendText,
        nextTierText,
        learnMore: linkResolver(learnMore?.document),
        sectionTitle,
        sectionInformation,
        yotpo,
        customer,
        basketPos,
        data,
        currentRoute,
        handleClick,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  data: any
  introGreeting: any
  link: any
  vipTierText: any
  annualSpendIcon: any
  annualSpendText: any
  nextTierText: any
  learnMore: any
  sectionTitle: any
  sectionInformation: any
  yotpo: any
  customer: any
  currentRoute: any
  basketPos: any
}

type InjectedProps = {
  data: any
  introGreeting: any
  link: any
  basketPos: any
  vipTierText: any
  annualSpendIcon: any
  annualSpendText: any
  nextTierText: any
  learnMore: any
  sectionTitle: any
  sectionInformation: any
  yotpo: any
  customer: any
  currentRoute: any
  handleClick: () => void
}
