import React, { memo } from "react"
import * as Components from "../../../types/components"
import { useImage } from "../../../hooks/useImage"

export const withLoyaltyImageHalfColour = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = `LoyaltyImageHalfColour`, headerImage }: ExternalProps) => {
    const { getFluidImage } = useImage()

    const props = {
      headerImage: getFluidImage(headerImage?.desktop, { maxWidth: 2000 }),
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  headerImage: any
}

type InjectedProps = {
  headerImage: any
}
