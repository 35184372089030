import tw, { styled, css } from "twin.macro"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

import { P, RichText } from "../../Styled/Text"

const styles = {
  default: tw`block`,
  item: tw`border-t border-grey-light`,
  tierSectionContainer: {
    default: tw`flex flex-col justify-center relative py-10 pt-40`,
    rewards: tw`flex flex-col justify-center relative pb-10 pt-0`,
  },

  styledImages: {
    default: tw`w-4/5 md:w-2/3`,
  },
}

//bg-orange-DEFAULT

export const TierSectionContainer = styled(RichText)`
  ${styles.tierSectionContainer.default}

  background: linear-gradient(180deg, #ff9f37 50%, #FFF 50%);
  margin-top: -1px;
  padding: 0;
`

export const StyledImage = styled(GatsbyImage)`
  ${styles.styledImages.default}

  margin: 0 auto;
  line-height: 0;
  max-height: 65rem;
`
